import React from 'react';
import { Typography } from '@material-ui/core';
import { SEO } from '../components/SEO';
import styled from '../styled';
import { Job } from '../types/Job';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import { CookieBar } from '../components/CookieBar';
import withRoot from '../withRoot';
import { Link } from 'gatsby';

type IContext = {
  jobs: Job[];
};

const JobsWrapper = styled('div')`
  margin: 200px auto;
  max-width: 800px;
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: ${(p) => p.theme.palette.primary.main};
  border-bottom: 1px solid;
`;

const Template = ({ pageContext }: { pageContext: IContext }) => {
  const { jobs } = pageContext;

  return (
    <>
      <>
        <Navigation />
        <SEO title={'Jobs at Affilimate'} pathname={`/jobs/`} />

        <JobsWrapper>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              margin: '0 auto 24px',
              fontWeight: 900
            }}
          >
            Work at Affilimate
          </Typography>
          <Typography variant="body1" color="textSecondary"></Typography>
          <br />
          <a href="https://jobs.polymer.co/affilimate" target="_blank">
            Find our open jobs here ➝
          </a>
          {/*jobs.map((job) => (
            <p key={job.id}>
              <StyledLink to={`/jobs/${job.slug.current}/`}>
                {job.title}
              </StyledLink>
            </p>
            ))*/}
        </JobsWrapper>
        <Footer />
        <CookieBar />
      </>
    </>
  );
};

export default withRoot(Template);
